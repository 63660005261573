import http from "@/utils/request";

export function getList(data) {
    return http({
        url: '/store/storeReportList.htm',
        method: 'post',
        data
    })
}



export function getExport(data) {
    return http({
        url: '/store/exportStoreReport.nd',
        method: 'post',
        data,
        responseType: 'blob'
    })
}