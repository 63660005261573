import Util from "@/utils/utils";
import { getList, getExport } from "./api";


export default {
  name: "inspection",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/center/inspection",
          name: "inspection",
          title: "巡店查询"
        }
      ],
      hideOrShowSize: true,
      columns: [
        {
          label: "门店编码",
          prop: "storyCode",
          width:150
        },
        {
          label: "门店名称",
          prop: "storeName",
          width:150
        },
        {
          label: "是否专卖",
          prop: "speclalshop",
          width:150
        },
        {
          label: "是否品牌园",
          prop: "isBrandGarden",
          width:180
        },
        {
          label: "城市级别",
          prop: "cityLevel",
          width:150
        },
        {
          label: "巡视人员编码",
          prop: "submiterCode",
          width:250
        },
        {
          label: "巡视人员姓名",
          prop: "userName",
          width:250
        },
        {
          label: "职务名称",
          prop: "positionName",
          width:150
        },
        {
          label: "分公司名称",
          prop: "org3name",
          width:200
        },
        {
          label: "上报地址",
          prop: "sumitAddress",
          width:150
        },
        {
          label: "备注",
          prop: "mem",
          width:150
        },
        {
          label: "巡店日期",
          prop: "addTime",
          width:150
        },
        {
          label: "操作",
          slot: "btnClick",
          ellipsis: true,
          fixed: 'right',
          width: 100,
        },
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
        storeCode: "",
        storeName: "",
        submiterCode: "",
        submiterName: "",
        org3name: "",
        speclalshop: "",
        isBrandGarden: "",
        startTime: "",
        endTime: "",
        isSink:'',//默认下沉门店
        pageNo: 1,
        pageSize: 10
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      showRadio:''
      //  isSink: topFilterCkd.isSink.name == "自有渠道" ? topFilterCkd.isSink.val : '', //下沉门店不传值
      //   isSinkisSink: topFilterCkd.isSink.name == "自有渠道" ?  '0' : '1', // 0不是下沉门店，1是下沉门店
      
    };
  },
  watch: {
    "ssearchList.submiterCode": function(val) {
      this.ssearchList.submiterCode = val.trim();
    },
    "ssearchList.storeName": function(val) {
      this.ssearchList.storeName = val.trim();
    },
    "ssearchList.storeCode": function(val) {
      this.ssearchList.storeCode = val.trim();
    },
    "ssearchList.submiterName": function(val) {
      this.ssearchList.submiterName = val.trim();
    },
    "ssearchList.org3name": function(val) {
      this.ssearchList.org3name = val.trim();
    }
  },
  mounted() {
    // 如果当前账号岗位不是自有渠道网格经理 这个默认为T
    // 否则传''
    if(this.$store.state.user.userInfo.account.jobs.indexOf(20231110)== -1){
      this.showRadio=true
      this.ssearchList.isSink = 'T'
    }else{
      this.showRadio=false
      this.ssearchList.isSink = ''
    }
    this.fun_date(-30);
    this.tablelist();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    // 跳转巡检记录
    gotoXJ(item){
      this.$router.push({
        path:'/center/terminalInspectionRecord',
        query:{
          checkUserCode:item.submiterCode,
          storeCode:item.storyCode,
          storeName:item.storeName,
          addTime:item.addTime
        }
      })
    },
    fun_date(num) {
      var date1 = new Date();
      var yuefen =
        date1.getMonth() + 1 < 10
          ? "0" + Number(date1.getMonth() + 1)
          : date1.getMonth()+1;
      var ri =
        date1.getDate() + 1 < 10 ? "0" + date1.getDate() : date1.getDate();
      var time1 = date1.getFullYear() + "-" + yuefen + "-" + ri;
      this.ssearchList.endTime = time1;
      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + num);
      var yu =
        date2.getMonth() + 1 < 10
          ? "0" + Number(date2.getMonth() + 1)
          : date2.getMonth()+1;
      var riq =
        date2.getDate() + 1 < 10 ? "0" + date2.getDate() : date2.getDate();
      var time2 = date2.getFullYear() + "-" + yu + "-" + riq;
      this.ssearchList.startTime = time2;
    },
    exportClick() {
      let data = JSON.parse(JSON.stringify(this.ssearchList));
      data.pageSize = 300000;
      data.pageNo = 1;
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "我的o2o.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },
    pageSearch(pager) {
      this.ssearchList.pageNo = pager.pageNo;
      this.ssearchList.pageSize = pager.pageSize;
      this.tablelist();
    },
    tablelist() {
      let data = this.ssearchList;
      getList(data).then(res => {
        this.tableData = res.data.list;
        if (res.data.list) {
          this.searchNumber = res.data.datasCount;
          this.pager.count = res.data.datasCount;
          // this.lastpagenum(res.data.totalPages);
        }
      });
    },
    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    // 重置
    reset() {
      this.ssearchList = {
        storeCode: "",
        storeName: "",
        submiterCode: "",
        submiterName: "",
        org3name: "",
        speclalshop: "",
        isBrandGarden: "",
        startTime: "",
        endTime: "",
        pageNo: 1,
        isSink:this.$store.state.user.userInfo.account.jobs.indexOf(20231110)== -1?'T':'',//默认下沉门店
      };
      this.tablelist();
    },

    // 分页
    pagesiezes(data, pageSize) {
      this.ssearchList.pageNo = data;
      this.ssearchList.pageSize = pageSize;
      this.tablelist();
    },
    handleChange(e) {
    },
    // 是否是品牌园
    handleChangePP(e) {
    },
    onChangeStartTime(e, time) {
      this.ssearchList.startTime = time;
    },
    onChangeEndTime(e, time) {
      this.ssearchList.endTime = time;
    },
    searchClick() {
      this.tablelist();
    }
  }
};
